// React
import React, { useContext } from "react";
import {
  StyledHasNoSubscription,
  StyledHasNoSubscriptionWrapper,
} from "./HasNoSubscription.styled";
import LogoutButton from "../../components/LogoutButton";
import { useTranslation } from "react-i18next";
import { StateUserContext } from "../../context/UserContext";
import { PrimaryButton } from "../../components/Button";
import { Icon } from "../../components";
import { updateRecruiterOrganization } from "../../api/endpoints/recruiter";
import { DispatchErrorContext } from "../../context/ErrorContext";
import { DispatchUserContext } from "../../context/UserContext";
import { Grid, Row, Col } from "../../components/Grid";
import { Flex } from "../../recruiter/style";

const HasNoSubscription = () => {
  const { t } = useTranslation();
  const { user } = useContext(StateUserContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const userDispatch = useContext(DispatchUserContext);

  const handleChangeClick = async (organizationId) => {
    try {
      // setLoading(true);
      const request = {
        body: {
          organizationId: organizationId,
        },
      };

      const response = await updateRecruiterOrganization(request);
      if (response.success) {
        // setLoading(false);
        userDispatch({
          type: "userUpdate",
          payload: response.data,
        });
        // userDispatch({ type: "auth0authentication", payload: true });
      } else {
        errorDispatch({
          type: "set_error",
          payload: response.data.data || response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      errorDispatch({
        type: "set_error",
        payload: err.message,
      });
    }
  };

  return (
    <StyledHasNoSubscription>
      <StyledHasNoSubscriptionWrapper>
        <Grid>
          <Row>
            <Col>
              <h1>Subscription Expired</h1>
              <p>
                Sorry, you do not have an active subscription to this service.{" "}
                <br />
                Please contact your HiTalento account manager to renew your
                subscription.
              </p>
            </Col>
          </Row>
          <Row justifyContent="center">
            <Flex widthSM="auto" mt="40">
              <LogoutButton red secondary>
                {t("common.logout", "Logout")}
              </LogoutButton>
              {user.role === "super" && (
                <PrimaryButton
                  white
                  uppercase
                  icon
                  ml="20"
                  justifyContent="center"
                  onClick={() => handleChangeClick(user.superOrganizationId)}
                >
                  Exit support mode
                  <Icon icon="arrrow_left_from_bracket" mr="20" scale="2" />
                </PrimaryButton>
              )}
            </Flex>
          </Row>
        </Grid>
        <br />
      </StyledHasNoSubscriptionWrapper>
    </StyledHasNoSubscription>
  );
};

export default HasNoSubscription;
