import React, { createContext, useReducer } from 'react';
import { isEmpty } from '../../utils/cleanObject';
import { isSubscriptionStatusOk } from '../../utils/stripe_utils';
import { formatDate } from '../../utils/various';
// import { StateUserContext } from "../../context/UserContext";

export const StatePositionContext = createContext();
export const DispatchPositionContext = createContext();

// return message based on state of subscription
const setSubMessage = (subscriptionObject, position, t) => {
  if (position?.unsolicited)
    return t('page.position.basic_subscription');
  if (
    !subscriptionObject ||
    isEmpty(subscriptionObject) ||
    !isSubscriptionStatusOk(subscriptionObject.status)
  )
    return t('page.position.no_active_subscription');
  if (
    isSubscriptionStatusOk(subscriptionObject.status) &&
    subscriptionObject.cancel_at
  )
    return `${t('page.position.subscription_will_be_cancelled')} ${formatDate(subscriptionObject.cancel_at * 1000)}`;
  if (isSubscriptionStatusOk(subscriptionObject.status)) {
    return `${t('page.position.subscription_subscribed')} ${formatDate(subscriptionObject.current_period_end * 1000)}`;
  }
};

const positionReducer = (state, action, t) => {

  switch (action.type) {
    case 'update_position_page_site': {
      return {
        ...state,
        single: {
          ...state.single,
          newfieldwithvalue: action.payload,
        }
      }
    }
    case 'init_positions': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'init_single_position': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'view_count': {
      return {
        ...state,
        count: action.payload
      }
    }
    case 'edit_position_view': {
      return {
        ...state,
        editPosition: action.payload
      }
    }
    case 'application_id': {
      return {
        ...state,
        appId: action.payload,
      }
    }
    case 'application_status': {
      return {
        ...state,
        substatus: action.payload,
      }
    }
    case 'refetch_positions': {
      return {
        ...state,
        refetch: action.payload,
      };
    }
    case 'candidate_view': {
      return {
        ...state,
        candidateView: action.payload,
      }
    }
    case 'create_position_modal': {
      return {
        ...state,
        createPositionModal: action.payload,
      }
    }
    case 'positions_collapsed': {
      return {
        ...state,
        collapsed: action.payload
      }
    }
    case 'loaded_single_position': {
      const { data, t } = action.payload;
      return {
        ...state,
        unsaved: false,
        isLoading: false,
        single: data,
        subMessage: setSubMessage(data.subscriptionInfo, data, t),
      };
    }
    case 'init_new_position': {
      const { baseDirectory, t, initialLanguage } = action.payload;
      return {
        ...state,
        isLoading: false,
        unsaved: true,
        isNew: true,
        single: {
          ...initialSinglePosition(baseDirectory, initialLanguage),
          title: t('page.position.new_position'),
        },
      };
    }
    case 'add_page': {
      return {
        ...state,
        single: {
          ...state.single,
          page: action.payload,
        },
      };
    }
    case 'update_position_field': {
      const [key, value] = action.payload;

      return {
        ...state,
        unsaved: true,
        single: {
          ...state.single,
          [key]: value,
        },
        anonUpdated: key === 'anon' && value !== state.single.anon,
      };
    }
    case 'updated_position': {
      const { data, t } = action.payload;
      return {
        ...state,
        unsaved: false,
        isNew: false,
        single: {
          ...state.single,
          ...data,
          addFiles: [],
          removeFiles: [],
        },
        subMessage: setSubMessage(data?.subscriptionInfo, data, t),
      };
    }
    case 'add_mandatory': {
      const mandatoryFields = [...state.single.mandatoryFields];
      const index = mandatoryFields.indexOf(action.payload);
      if (index === -1) {
        mandatoryFields.push(action.payload);
      }
      return {
        ...state,
        unsaved: true,
        single: {
          ...state.single,
          mandatoryFields: mandatoryFields,
        },
      };
    }
    case 'toggle_position_status': {
      return {
        ...state,
        single: {
          ...state.single,
          status: action.payload,
        },
      };
    }
    case 'remove_mandatory': {
      const mandatoryFields = [...state.single.mandatoryFields];
      const index = mandatoryFields.indexOf(action.payload);
      if (index > -1) {
        mandatoryFields.splice(index, 1);
      }
      return {
        ...state,
        unsaved: true,
        single: {
          ...state.single,
          mandatoryFields: mandatoryFields,
        },
      };
    }
    case 'loaded_positions': {
      return {
        ...state,
        isLoading: false,
        positions: action.payload,
      };
    }
    case 'loaded_applications': {
      return {
        ...state,
        applications: action.payload,
      };
    }
    case 'refresh_positions': {
      return {
        ...state,
        refresher: !state.refresher,
      };
    }
    case 'update_position_status_in_all_positions': {
      const { data } = action.payload;
      const positions = state.positions.map((position) => {
        if (position.id === data.id) {
          return {
            ...position,
            status: data.status,
          };
        }
        return position;
      });
      return {
        ...state,
        positions: positions,
      };
    }
    case 'error_positions': {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

/**
 * Return initial state to create a new page!
 * @param {string} initialLanguage position language ( en-US | de-DE etc... )
 * @returns {object} initial object to populate state!
 */
export const initialSinglePosition = (
  initialLanguage
) => {
  return {
    title: 'New position',
    description: '',
    competencies: [],
    languages: [],
    files: [],
    light: true,
    descriptionUrl: '',
    positionLanguage: initialLanguage || 'en-US',
    mandatoryFields: ['upload_cv', 'about'],
    anon: false,
  };
};

export const PositionProvider = ({ children }) => {
  // const { isAuthenticated } = useContext(StateUserContext);

  const positionInitialState = {
    positions: [],
    single: {},
    single: {
      status: 'inactive', // Set the initial status here
    },
    isLoading: false,
    applications: [],
    isError: false,
    subMessage: 'Subscription status',
    refresher: false,
    unsaved: false,
    isNew: false,
    anonUpdated: false,
    count: 0,
    substatus: '',
    editPosition: false,
    createPositionModal: false,
    collapsed: false,
    appId: 0,
    refetch: false,
    candidateView: false,
  };

  const [state, dispatch] = useReducer(
    positionReducer,
    positionInitialState
  );

  return (
    <DispatchPositionContext.Provider value={dispatch}>
      <StatePositionContext.Provider value={state}>
        {children}
      </StatePositionContext.Provider>
    </DispatchPositionContext.Provider>
  );
};
