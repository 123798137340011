// React
import React, { useContext, useState, useEffect } from "react";

// API endpoints - custom hooks
import { useFetchPages } from "../cp/useFetchPages";

// Styled components
import { StyledSideMenu, MenuContainer, PlusButton, AdminList } from "./style";
import { NavLink } from "./style";

// Components
import { Icon } from "../../../components";
import CreatePosition from "../../views/Position/CreatePosition/CreatePosition";

// Router
import { useLocation, Link } from "react-router-dom";

// State management and translations
import { StateUserContext } from "../../../context/UserContext";
import {
  StatePositionContext,
  DispatchPositionContext,
} from "../../context/PositionContext";
import { useTranslation } from "react-i18next";

const SideMenu = () => {
  const { t } = useTranslation();
  const { user } = useContext(StateUserContext);
  const { pathname } = useLocation();
  const [userLanguage, setUserLanguage] = useState("");

  const positionDispatch = useContext(DispatchPositionContext);

  const { createPositionModal } = useContext(StatePositionContext);

  const isActive = (route) => {
    // Check if the current location matches the given route
    return (
      pathname.startsWith(route) || (route === "/position/" && pathname === "/")
    );
  };

  const { pages } = useFetchPages({});

  const cpSiteLink = `/cp/site/${userLanguage}`;

  useEffect(() => {
    const language = pages[0]?.site?.language;

    if (language) {
      setUserLanguage(language);
    } else {
      // Assuming user.language is already defined
      if (user.language === "da-DK") {
        setUserLanguage("da");
      } else {
        setUserLanguage("en");
      }
    }
  }, [pages, user]);

  return (
    <>
      {createPositionModal && <CreatePosition />}
      <Link to={"/position/create"}>
        <PlusButton>
          <Icon icon="navPlus" className="plus" />
        </PlusButton>
      </Link>
      <StyledSideMenu>
        <Link to="/position">
          <Icon
            color="#fff"
            icon="new_white_brand"
            nomargin
            className="nav_brand"
            p="0"
          />
        </Link>
        <MenuContainer>
          <ul>
            <li>
              <NavLink
                to="/position/"
                active={isActive("/position/").toString()}
                hascreatebutton="true"
              >
                <Icon
                  icon="suitcase"
                  scale={0.8}
                  nomargin
                  color="#fff"
                  className="nav_icon"
                  active={pathname.startsWith("/position").toString()}
                  inactive={!pathname.startsWith("/position")}
                />
                {t("common.position.plural")}
                <Icon
                  icon="circle_plus"
                  scale={1}
                  className="create_position"
                  onClick={() =>
                    positionDispatch({
                      type: "create_position_modal",
                      payload: true,
                    })
                  }
                />
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink
                to={cpSiteLink}
                active={pathname.startsWith("/cp").toString()}
              >
                <Icon
                  icon="page"
                  scale={0.8}
                  nomargin
                  className="nav_icon"
                  color="#fff"
                  active={pathname.startsWith("/cp").toString()}
                  inactive={!pathname.startsWith("/cp")}
                />
                {t("common.careerPages", "Career Pages")}
              </NavLink>
            </li>
          </ul>
          {(user.role === "super" || user.role === "owner") && (
            <AdminList>
              {user.role === "super" && (
                <ul>
                  <li>
                    <NavLink
                      to="/superadmin"
                      active={pathname.startsWith("/superadmin").toString()}
                    >
                      <Icon
                        icon="user_secret"
                        scale={1}
                        nomargin
                        className="nav_icon"
                        color="#fff"
                        active={pathname
                          .startsWith("/administration")
                          .toString()}
                        inactive={!pathname.startsWith("/administration")}
                      />
                      Superadmin
                    </NavLink>
                  </li>
                </ul>
              )}
              <ul>
                <li>
                  <NavLink
                    to="/administration"
                    active={pathname.startsWith("/administration").toString()}
                  >
                    <Icon
                      icon="file"
                      scale={1}
                      nomargin
                      className="nav_icon"
                      color="#fff"
                      active={pathname.startsWith("/administration").toString()}
                      inactive={!pathname.startsWith("/administration")}
                    />
                    {t("common.administration")}
                  </NavLink>
                </li>
              </ul>
              <ul>
                <li>
                  <NavLink
                    to="/settings"
                    active={pathname.startsWith("/settings").toString()}
                  >
                    <Icon
                      icon="settings"
                      scale={1}
                      nomargin
                      className="nav_icon"
                      color="#fff"
                      active={pathname.startsWith("/settings").toString()}
                      inactive={!pathname.startsWith("/settings")}
                    />
                    {t("common.settings")}
                  </NavLink>
                </li>
              </ul>
            </AdminList>
          )}
        </MenuContainer>
      </StyledSideMenu>
    </>
  );
};

export default SideMenu;
