// React
import React, { useEffect, useContext, useState } from "react";

// API endpoints, custom hooks, and utility functions
import { getRecruiters } from "../../../api/endpoints/recruiter";

// Components
import { MegaHeader } from "../../components";
import SupportAccess from "./SupportAccess/SupportAccess";

// State management
import { DispatchErrorContext } from "../../../context/ErrorContext";
import { DispatchOrganizationContext } from "../../context/OrganizationContext";

// ABSOLUTELY NECESSARY
import { SuperadminWrapper } from "./Superadmin.styled";
import { TopBarTop, TopBarBottom } from "../../components/TopBar/style";
import { MobileMenuButtons, MobileMenuButton } from "../../style";

const Superadmin = () => {
  const organizationDispatch = useContext(DispatchOrganizationContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const [activeLink, setActiveLink] = useState("manage");

  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        organizationDispatch({ type: "users_init" });
        const response = await getRecruiters();
        if (response.success) {
          organizationDispatch({
            type: "loaded_users",
            payload: response.data,
          });
        } else {
          errorDispatch({ type: "set_error", payload: response.data });
        }
      } catch (err) {
        errorDispatch({ type: "set_error", payload: err });
      }
    };
    fetchRecruiters();
  }, [errorDispatch, organizationDispatch]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <SuperadminWrapper>
      <TopBarTop>
        <MegaHeader text="Superadmin" noIcon />
      </TopBarTop>
      <TopBarBottom>
        <MobileMenuButtons>
          <MobileMenuButton
            onClick={() => handleLinkClick("manage")}
            isActive={activeLink === "manage"}
          >
            Support access
          </MobileMenuButton>
        </MobileMenuButtons>
      </TopBarBottom>
      {activeLink === "manage" && <SupportAccess />}
    </SuperadminWrapper>
  );
};

export default Superadmin;
