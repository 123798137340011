// React
import React, { useContext, useEffect, useState } from "react";

// Styled components
import {
  Scrollable,
  SupportAccessWrapper,
  ManageOrganizations,
  StyledTable,
  TableHeader,
  TableSearch,
  TableHeaderElements,
  CurrentSuperOrganization,
  NoOrganizationCard,
} from "./SupportAccess.styled";

// Translation
import { useTranslation } from "react-i18next";

import { Flex } from "../../../style";
import { Grid, Row, Col } from "../../../../components/Grid";
import { Typography } from "../../../../components/Typography";

import OrganizationCard from "./OrganizationCard/OrganizationCard";
import { InputFieldWithLabel } from "../../../../components";
// Context
import { StateSuperContext } from "../../../context/SuperContext";
import { StateUserContext } from "../../../../context/UserContext";

const SupportAccess = () => {
  const { t } = useTranslation();
  const { organizations } = useContext(StateSuperContext);
  const { user } = useContext(StateUserContext);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [currentOrganizations, setCurrentOrganizations] = useState([]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    // All organizations
    const filtered = Object.values(organizations)
      .filter((organization) =>
        organization.name.toLowerCase().includes(searchValue.toLowerCase())
      )
      .sort((a, b) => a.id - b.id);
    setFilteredOrganizations(filtered);

    // Current organization
    const currentFiltered = Object.values(organizations).filter(
      (organization) => organization.id === user.organizationId
    );
    setCurrentOrganizations(currentFiltered);
  }, [searchValue, organizations, user.organizationId]);

  return (
    <Scrollable>
      <SupportAccessWrapper>
        <Grid fluid>
          {user.superOrganizationId !== user.organizationId ? (
            <Row mb="15">
              <Col>
                <CurrentSuperOrganization>
                  <Flex>
                    <Typography tag="p" mb="15">
                      You are currently viewing the following organization
                    </Typography>
                  </Flex>
                  {currentOrganizations.map((organization) => (
                    <OrganizationCard
                      key={organization.id}
                      organization={organization}
                      type="current"
                    />
                  ))}
                </CurrentSuperOrganization>
              </Col>
            </Row>
          ) : (
            <Row mb="15">
              <Col>
                <NoOrganizationCard>
                  <Typography tag="p" mb="10" bold>
                    You are <strong>NOT</strong> currently in support mode and
                    viewing your own organization.
                  </Typography>
                  <Typography tag="p" mb="0">
                    Select an organization in the list below to go into support
                    mode and view as that organization.
                  </Typography>
                </NoOrganizationCard>
              </Col>
            </Row>
          )}
        </Grid>
        <ManageOrganizations>
          <TableSearch>
            <InputFieldWithLabel
              name="firstName"
              label="Search filter"
              placeholder="Organization name"
              value={searchValue}
              onChange={handleChange}
              byline="Search for an organization by name"
            />
          </TableSearch>
          <StyledTable>
            <TableHeader>
              <TableHeaderElements>
                <Flex widthSM="100%">
                  <Typography tag="p" mb="0" width="50" bold>
                    ID
                  </Typography>
                  <Typography tag="p" mb="0" bold>
                    Organization
                  </Typography>
                </Flex>
                <Flex widthSM="300px" justify="space-around" ml="20">
                  <Typography tag="p" mb="0" bold>
                    {t("common.dateAdded", "Date added")}
                  </Typography>
                  <Typography tag="p" mb="0" bold>
                    {t("common.lastActive", "Last active")}
                  </Typography>
                </Flex>
                <Flex widthSM="100px" justify="space-around" ml="20">
                  <Typography tag="p" mb="0" bold>
                    {t("common.careerPage", "Career page")}
                  </Typography>
                </Flex>
                <Flex widthSM="75px" justify="space-around" ml="20">
                  <Typography tag="p" mb="0" bold>
                    Switch
                  </Typography>
                </Flex>
              </TableHeaderElements>
            </TableHeader>
            {filteredOrganizations.length > 0 ? (
              filteredOrganizations.map((organization) => (
                <OrganizationCard
                  key={organization.id}
                  organization={organization}
                  type="all"
                />
              ))
            ) : (
              <Typography tag="errorMessage" mt="20" mb="20" ml="20" bold>
                No organizations found.
              </Typography>
            )}
          </StyledTable>
        </ManageOrganizations>
      </SupportAccessWrapper>
    </Scrollable>
  );
};

export default SupportAccess;
