// React
import React, { useContext, useEffect } from "react";

// React router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";

// HOC
import ProtectedRoute from "../components/ProtectedRoute";

// Components
import { SideMenu, MainContent, TopBar, MainContainer, SuperTopBar } from "./components";
import { NoMatch, Version } from "../components";

// Views
import {
  AllPositions,
  Position,
  AllApplications,
  InviteCandidate,
  Superadmin,
  Administration,
  User,
  Frontpage,
  Settings
} from "./views";
import PageView from "./views/Cp/PageView/PageView";

// State management
import { StateUserContext } from "../context/UserContext";
import {
  getGenericLocalStorage,
  setGenericLocalStorage,
} from "../utils/localSave";

const Admin = () => {
  const { user } = useContext(StateUserContext);

  //key go back history saves
  let location = useLocation();
  useEffect(() => {
    const { pathname } = location;
    if (
      pathname.includes("/position/") ||
      pathname.includes("candidate/all") ||
      pathname === "/"
    ) {
      //save to key back location
      let currentAdminSettings = {};
      if (getGenericLocalStorage("admin_settings")) {
        currentAdminSettings = getGenericLocalStorage("admin_settings", true);
      }
      setGenericLocalStorage(
        "admin_settings",
        { ...currentAdminSettings, keyBackLocation: location.pathname },
        true
      );
    }
  }, [location]);

  return (
    <MainContainer>
      <SuperTopBar />
      <SideMenu />
      <MainContent>
        <TopBar />
        <Router>
          <Switch>
            <ProtectedRoute exact path="/" component={Frontpage} />
            <ProtectedRoute path="/success" component={AllPositions} />
            <ProtectedRoute path="/settings" component={Settings} />
            {/* CP */}
            <ProtectedRoute path="/cp/site/:language" component={PageView} />
            <ProtectedRoute exact path="/position" component={AllPositions} />
            <ProtectedRoute exact path="/position/:id" component={Position} />
            <ProtectedRoute
              exact
              path="/candidate/all"
              component={AllApplications}
            />
            <ProtectedRoute
              exact
              path="/candidate/invite"
              component={InviteCandidate}
            />
            {user.role === "super" ? (
              <ProtectedRoute exact path="/superadmin" component={Superadmin} />
            ) : (
              user.role === "owner" && <Route path="/superadmin" render={() => <Redirect to="/settings" />} />
            )}

            {(user.role === "super" || user.role === "owner") && (
              <>
                <ProtectedRoute exact path="/administration" component={Administration} />
                <ProtectedRoute exact path="/user/:id" component={User} />
              </>
            )}
            <Route component={NoMatch} />
          </Switch>
        </Router>
        <Version />
      </MainContent>
    </MainContainer>
  );
};

export default Admin;
