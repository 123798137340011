// React
import React, { useContext } from "react";

import { SuperadminWrapper } from "./SuperTopBar.styled";
import { StateUserContext } from "../../../context/UserContext";

const Superadmin = () => {
  const { user } = useContext(StateUserContext);

  if (user?.superOrganizationId === user?.organizationId) {
    return null;
  }

  if (user.role === "super") {
    return <SuperadminWrapper></SuperadminWrapper>;
  } else {
    return null;
  }
};

export default Superadmin;
