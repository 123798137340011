import axios from "axios";
import { parseRequest } from "../utilities/queries";
import { errorResponseHandler, responseHandler } from "../utilities/response";

export const createPosition = async (request = {}) => {
  const { body } = parseRequest(request);
  try {
    const response = await axios.post("/position", body);
    return responseHandler(response);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const togglePosition = async (request = {}) => {
  const { selector, params } = parseRequest(request);
  try {
    const data = await axios.put(`/position/toggle/${selector}${params ? "/" + params : ""}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const deactivatePositions = async (request = {}) => {
  const { body } = parseRequest(request);
  if (!body) return;
  try {
    const data = await axios.put(`/position/deactivate`, body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const updatePosition = async (request = {}) => {
  const { selector, body, params } = parseRequest(request);
  try {
    const data = await axios.put(
      `/position/${selector ? selector : ""}${params ? "/" + params : ""}`,
      body
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const deletePosition = async (request = {}) => {
  const { selector } = parseRequest(request);
  try {
    const data = await axios.delete(`/position/${selector ? selector : ""}`);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getPositions = async (request = {}) => {
  const { selector, params } = parseRequest(request);
  try {
    const data = await axios.get(
      `/position/${selector ? selector : ""}${params ? params : ""}`
    );
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};
