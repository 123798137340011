import axios from "axios";
import { errorResponseHandler,  responseHandler } from "../utilities/response";

export const getOrganization = async () => {
  try {
    const data = await axios.get("/organization");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const addOrganization = async (input) => {
  try {
    const data = await axios.post("/organization", input);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const updateOrganization = async (input) => {
  try {
    const data = await axios.put("/organization", input);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getBranding = async( request ) => {
  try {
    const data = await axios.get("/branding");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
}

export const updateBranding = async (request) => {
  const { body } = request;
  try {
    const data = await axios.put("/branding", body);
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};

export const getOrganizations = async () => {
  try {
    const data = await axios.get("/organization/all");
    return responseHandler(data);
  } catch (err) {
    return errorResponseHandler(err);
  }
};