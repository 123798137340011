// React
import React, { useState, useEffect, useContext } from "react";

// API endpoints - custom hooks
import { purchasePromotion } from "../../../../api/endpoints/promotions";
import { getPromotion } from "../../../../api/endpoints/promotions";
import { formatAmount } from "../../../../utils/various";

// Components
import { PrimaryButton } from "../../../../components/Button";
import { Icon, Modal } from "../../../../components";
import { Grid, Row } from "../../../../components/Grid";
import { Typography } from "../../../../components/Typography";
import { RadioButton } from "../../../components/Buttons/Buttons";

// Translations
import { useTranslation, Trans } from "react-i18next";

// State management
import { SubscriptionContext } from "../../../context/SubscriptionContext";

// Styled components
import {
  PromoteHiBoostWrapper,
  HiBoostSolution,
  HiBoostDescription,
} from "./PromoteHiBoost.styled";
import Loading from "../../../../components/Loader/Loading";
import PaymentModal from "../../../../components/PaymentModal/PaymentModal";
import ButtonLoading from "../../../../components/Loader/ButtonLoading";

const PromoteHiBoost = ({ onClose, single, onPromoteConfirmation }) => {
  const { t } = useTranslation();

  // State
  const [promotionData, setPromotionData] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    promotionData?.data[2]?.prices[0]?.id
  );
  const [isLoading, setIsLoading] = useState(true);
  const [clickLoading, setClickLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [priceId, setPriceId] = useState(selectedValue);
  const [positionId, setPositionId] = useState();
  const [payWithInvoice, setPayWithInvoice] = useState(false);
  const [priceLabel, setPriceLabel] = useState("");
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [displayPromote, setDisplayPromote] = useState(true);
  const [promoteConfirmation, setPromoteConfirmation] = useState(false);

  const {
    state: { hasPayment },
  } = useContext(SubscriptionContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPromotion();
        setPromotionData(data);
        setPositionId(single.id);
        setIsLoading(false); // Set loading to false once data is fetched
        if (data?.data && data.data.length > 0) {
          const lastPromotion = data.data[data.data.length - 1];
          const dkkPrice = lastPromotion.prices.find(
            (price) => price.currency === "dkk"
          );
          setSelectedValue(dkkPrice.id);
          setPriceId(dkkPrice.id);
          setPriceLabel(formatAmount(dkkPrice.unit_amount, dkkPrice.currency));
        }
      } catch (error) {
        console.error("API Error:", error);
        setIsLoading(false); // Set loading to false in case of an error
        setError(true);
      }
    };
    fetchData();
  }, []);

  // Onchange handler
  const handleRadioButtonChange = (label, value) => {
    setSelectedValue(value);
    setPriceId(value);
    setPriceLabel(label); // Update the priceLabel state with the selected label
  };

  const handleToggleCard = () => {
    setPayWithInvoice(false);
  };

  const handleToggleInvoice = () => {
    setPayWithInvoice(true);
  };

  const handlePurchase = async () => {
    try {
      if (!hasPayment && !payWithInvoice) {
        setAddPaymentModal(true);
        return;
      }
      setClickLoading(true);
      // Create the request body
      const requestBody = {
        priceId,
        positionId,
        payWithInvoice,
      };

      // Make the API call
      await purchasePromotion(requestBody);
      // Handle the response
      setClickLoading(false);
      setSuccess(true);
      setDisplayPromote(false);
      setPromoteConfirmation(true);
    } catch (error) {
      // Handle errors
      console.error("API Error:", error);
    }
  };

  const onCloseHandler = () => {
    setPromoteConfirmation(false);
    onPromoteConfirmation(false);
  };

  // Reverse the order of promotionData?.data before mapping
  const reversedPromotionData = promotionData?.data.slice().reverse();

  return (
    <>
      <ButtonLoading loading={clickLoading} success={success} error={error} />
      <Loading loading={isLoading} />
      {addPaymentModal && (
        <PaymentModal
          onCancel={() => setAddPaymentModal(false)}
          returnUrl={`position/${positionId}`}
        />
      )}
      {promoteConfirmation && (
        <>
          <Modal title={t("common.successPurchase")} onClose={onCloseHandler}>
            <Grid padding="34" alignCenter>
              <Typography tag="p">
                {t("common.successPurchase_desc1")}
              </Typography>
              <Typography tag="p" nomargin>
                {t("common.successPurchase_desc2")}
              </Typography>
              <PrimaryButton mt="25" red onClick={onCloseHandler}>
                {t("common.greatThanks")}
                <Icon icon="checkmark" scale="3" mr="10" />
              </PrimaryButton>
            </Grid>
          </Modal>
        </>
      )}
      {displayPromote && (
        <>
          <PromoteHiBoostWrapper>
            <HiBoostDescription>
              <Typography tag="p" center nomargin padding={25}>
                <Trans i18nKey="common.hiBoostPitch">
                  {t(
                    "hiboost.modal.haveYourPositionPromoted",
                    "Have your position promoted with HiBoost talent marketing."
                  )}
                </Trans>
              </Typography>
            </HiBoostDescription>
            <HiBoostSolution>
              <Typography tag="p" center nomargin padding={25}>
                {t("common.selectHiBoost")}
              </Typography>
              <Row justifyContent="center" mt="25">
                <label className="label">
                  <input
                    type="checkbox"
                    checked={!payWithInvoice} // Inverse of payWithInvoice
                    onChange={handleToggleCard}
                  />
                  {t("common.payWithCard")}
                </label>
                <label className="label">
                  <input
                    type="checkbox"
                    checked={payWithInvoice}
                    onChange={handleToggleInvoice}
                  />
                  {t("common.payWithInvoice")}
                </label>
              </Row>
              <Row justifyContent="center" mt="25" mb="25">
                {reversedPromotionData?.map((item, index) =>
                  item.prices.map((price) => {
                    if (price.currency === "dkk") {
                      return (
                        <RadioButton
                          key={price.id}
                          label={formatAmount(
                            price.unit_amount,
                            price.currency
                          )}
                          value={price.id}
                          selectedValue={selectedValue}
                          mr={
                            index === reversedPromotionData.length - 1
                              ? ""
                              : "25"
                          }
                          onChange={(value) =>
                            handleRadioButtonChange(
                              formatAmount(price.unit_amount, price.currency),
                              value
                            )
                          }
                        />
                      );
                    }
                  })
                )}
              </Row>
              <Row justifyContent="space-between" mwMD="480" hCenter>
                <Typography tag="p" bold>
                  HiBoost
                </Typography>
                <Typography tag="p" bold>
                  {priceLabel}
                </Typography>
              </Row>
              <Row mwMD="480" hCenter>
                <Typography tag="p" large width="100%" nomargin mt="10">
                  <Trans i18nKey="component.purchase_modal.terms">
                    By making this purchase you are agreeing to our
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`${process.env.REACT_APP_TERMS}`}
                    >
                      terms and conditions
                    </a>
                  </Trans>
                </Typography>
              </Row>
            </HiBoostSolution>
            <PrimaryButton onClick={handlePurchase} red mt="25">
              {t("common.purchaseHiBoost")}
              <Icon icon="cart" mr="10" />
            </PrimaryButton>
            <PrimaryButton transparent mt="18" onClick={onClose} mb="15">
              {t("common.cancel")}
              <Icon icon="cross" mr="10" invert />
            </PrimaryButton>
          </PromoteHiBoostWrapper>
        </>
      )}
    </>
  );
};

export default PromoteHiBoost;
