// React
import React, { useState, useContext, useEffect, useRef } from "react";

// Styled component
import { TableContent, TableActions } from "./UsersCard.styled";
import { Flex } from "../../../../style";
import { Row, Col } from "../../../../../components/Grid";

// Components
import { Icon, Modal } from "../../../../../components";
import { Formik } from "formik";
import { Field } from "formik";
import { InputFieldWithLabel } from "../../../../../components";
import ButtonLoading from "../../../../../components/Loader/ButtonLoading";

// Utility functions - API endpoints - custom hooks
import { formatDate } from "../../../../../utils/various";
import { UsersWrapper } from "../ManageUsers.styled";
import DeleteRecruiter from "../../../Modals/DeleteRecruiter/DeleteRecruiter";
import { useTranslation } from "react-i18next";
import { deleteRecruiter } from "../../../../../api/endpoints/recruiter";
import { updateRecruiter } from "../../../../../api/endpoints/recruiter";
import Observer from "../../../Modals/AddRecruiter/Observer";

// State management
import {
  DispatchOrganizationContext,
  StateOrganizationContext,
} from "../../../../context/OrganizationContext";
import { DispatchMessageContext } from "../../../../../context/MessageContext";
import { DispatchErrorContext } from "../../../../../context/ErrorContext";
import {
  DispatchAdministrationContext,
  StateAdministrationContext,
} from "../../../../context/AdministrationContext";
import { SubscriptionContext } from "../../../../context/SubscriptionContext";
import { Typography } from "../../../../../components/Typography";
import { PrimaryButton } from "../../../../../components/Button";
import { Grid } from "../../../../../components/Grid";
import ManageAccess from "../../../../components/ManageAccess/ManageAccess";
import SelectedPositions from "./SelectedPositions";

const UsersCard = ({ users, setFetchTrigger }) => {
  const [selectedUser, setSelectedUser] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const { t } = useTranslation();

  const { positionAccess } = useContext(StateOrganizationContext);
  const organizationDispatch = useContext(DispatchOrganizationContext);
  const messageDispatch = useContext(DispatchMessageContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const administrationDispatch = useContext(DispatchAdministrationContext);
  const { deleteSuccess } = useContext(StateAdministrationContext);
  const {
    state: { privileges },
  } = useContext(SubscriptionContext);

  //Formik external form handling, use Observer to read values
  const formRef = useRef();
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    setUserArray(Array.isArray(users) ? users : [users]);
  }, [users]);

  // useEffect(() => {
  //   console.log("positionAccess", positionAccess);
  // }, [positionAccess]);

  if (!users) {
    return null; // Render nothing if users is undefined or null
  }

  const handleDelete = async (recruiterId) => {
    try {
      organizationDispatch({ type: "init_update_users" });
      const request = { selector: recruiterId };
      const response = await deleteRecruiter(request);
      if (response.success) {
        messageDispatch({
          type: "set_message",
          payload: t("page.all_users.user_deleted_message"),
        });
        organizationDispatch({ type: "removed_users", payload: [recruiterId] });
        administrationDispatch({ type: "delete_success", payload: true });
      } else {
        errorDispatch({ type: "set_error", payload: response.data.data });
      }
    } catch (err) {
      errorDispatch({ type: "set_error", payload: err });
    }
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user.id);
    setShowDeleteModal(true);
  };

  const handleEditClick = (user) => {
    setEditModal(true);
    setSelectedUser(user);
    setFormValues({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  };

  const updateUser = (updatedUser) => {
    setUserArray((prevUsers) =>
      prevUsers.map((user) =>
        user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      )
    );
  };

  const handleModalClose = () => {
    setEditModal(false);
  };

  return (
    <>
      <ButtonLoading loading={loading} success={isLoaded} nobg />
      {editModal && (
        <>
          <Modal title={t("common.editRecruiter")} onClose={handleModalClose}>
            <Grid padding="32" alignCenter fluid>
              <Typography tag="p">{t("common.changeDetails")}</Typography>
              <Formik
                initialValues={{
                  email: selectedUser.email,
                  firstName: selectedUser.firstName,
                  lastName: selectedUser.lastName,
                }}
                onSubmit={async (values, { resetForm }) => {
                  try {
                    setLoading(true);
                    const request = {
                      selector: selectedUser.id,
                      body: {
                        ...values,
                        positionsAccess: positionAccess,
                      },
                    };

                    const response = await updateRecruiter(request);
                    if (response.success) {
                      {
                        selectedUser.role !== "owner" &&
                          selectedUser.role !== "super" &&
                          setFetchTrigger((prev) => !prev);
                      }
                      updateUser({ id: selectedUser.id, ...values }); // Update the user in state
                      setLoading(false);
                      setIsLoaded(true);
                    } else {
                      errorDispatch({
                        type: "set_error",
                        payload: response.data.data || response.data.message,
                      });
                    }
                  } catch (err) {
                    console.log(err);
                    errorDispatch({
                      type: "set_error",
                      payload: err.message,
                    });
                  }
                }}
                innerRef={formRef}
              >
                {(props) => {
                  const { handleSubmit } = props;
                  return (
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                      <Observer setValues={setFormValues} />
                      <Row smallerInnerGutter>
                        <Col>
                          <Field
                            tiny
                            label={false}
                            as={InputFieldWithLabel}
                            // placeholder={selectedUser.firstName}
                            placeholder={t("common.first_name", "First name")}
                            name="firstName"
                          />
                        </Col>
                        <Col>
                          <Field
                            tiny
                            label={false}
                            as={InputFieldWithLabel}
                            // placeholder={selectedUser.lastName}
                            placeholder={t("common.last_name", "Last name")}
                            name="lastName"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            as={InputFieldWithLabel}
                            tiny
                            label={false}
                            // placeholder={selectedUser.email}
                            placeholder={t("common.email", "Email")}
                            name="email"
                          />
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
            {selectedUser?.role !== "owner" && (
              <ManageAccess user={selectedUser} />
            )}
            <Grid padding="32" alignCenter fluid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <PrimaryButton
                  transparent
                  onClick={() => handleDeleteClick(selectedUser)}
                >
                  {t("common.deleteRecruiter")}
                  <Icon icon="trash" mr="10" />
                </PrimaryButton>
                <PrimaryButton red onClick={handleSubmit}>
                  {t("common.save")}
                  <Icon icon="save" mr="10" />
                </PrimaryButton>
              </div>
            </Grid>
          </Modal>
        </>
      )}
      <UsersWrapper>
        {userArray.map((user) => (
          <Flex key={user.id} align="flex-start" borderBottom>
            <TableContent>
              <Flex flexDirection="column" align="flex-start">
                <Typography tag="p" nomargin ml="0" large bold>
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography tag="p" nomargin ml="0" large>
                  {user.email}
                </Typography>
                {user.role !== "owner" && <SelectedPositions user={user} />}
              </Flex>
              <h3>{user.role}</h3>
              <p>{formatDate(user.createdAt)}</p>
              <p>{formatDate(user.updatedAt)}</p>
            </TableContent>
            <TableActions>
              <Icon icon="edit" pointer onClick={() => handleEditClick(user)} />
              {user.role === "owner" ? (
                <Icon icon="trash" disabled lowestOpacity />
              ) : (
                <Icon
                  icon="trash"
                  pointer
                  onClick={() => handleDeleteClick(user)}
                />
              )}
            </TableActions>
          </Flex>
        ))}
      </UsersWrapper>
      {showDeleteModal && (
        <DeleteRecruiter
          user={selectedUser}
          key={selectedUser} // Add key prop here
          onCancel={() => setShowDeleteModal(false)}
          onDelete={() => handleDelete(selectedUser)}
        />
      )}
      {deleteSuccess && (
        <Modal
          title={t("common.recruiterRemoved")}
          onClose={() =>
            administrationDispatch({ type: "delete_success", payload: false })
          }
        >
          <Grid padding={25} fluid>
            <Typography tag="p" center>
              {t("common.recruiterRemovedDesc")}
            </Typography>
            <PrimaryButton
              onClick={() =>
                administrationDispatch({
                  type: "delete_success",
                  payload: false,
                })
              }
              center
              red
              mt="25"
            >
              {t("common.great")}
              <Icon icon="thumbs_up" mr="10" />
            </PrimaryButton>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default UsersCard;
