// React
import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "../../../../../components/Typography";
import {
  OrganizationWrapper,
  TableContent,
  LargeOrganizationCard,
} from "./OrganizationCard.styled";
import { updateRecruiterOrganization } from "../../../../../api/endpoints/recruiter";
import { Flex } from "../../../../style";
import { Grid, Row, Col } from "../../../../../components/Grid";
import {
  Icon,
  InputFieldWithLabel,
  InputFieldWithLink,
} from "../../../../../components";
import { InputLabel, LabelByline } from "../../../../../components/Input/Input";

import { PrimaryButton } from "../../../../../components/Button";
import { logoutFunction } from "../../../../../components/LogoutButton";
import { DispatchErrorContext } from "../../../../../context/ErrorContext";
import {
  DispatchUserContext,
  StateUserContext,
} from "../../../../../context/UserContext";
import { formatDate } from "../../../../../utils/various";

const OrganizationCard = ({ organization, type }) => {
  const isProduction = process.env.NODE_ENV === "production";
  const reactAppUrl = process.env.REACT_APP_CP_URL;
  const url = reactAppUrl + "/" + organization.slug + "/";
  const errorDispatch = useContext(DispatchErrorContext);
  const userDispatch = useContext(DispatchUserContext);
  const { user } = useContext(StateUserContext);
  const { logout } = useAuth0();

  const handleChangeClick = async (organizationId) => {
    try {
      const request = {
        body: {
          organizationId: organizationId,
        },
      };

      const response = await updateRecruiterOrganization(request);

      if (response.success) {
        logoutFunction(logout);
        // userDispatch({ type: "userUpdate", payload: response.data });
      } else {
        errorDispatch({
          type: "set_error",
          payload: response.data.data || response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      errorDispatch({
        type: "set_error",
        payload: err.message,
      });
    }
  };

  let stripeCustomerUrl = "";
  let stripeSubscriptionUrl = "";
  if (type === "current") {
    if (isProduction) {
      stripeCustomerUrl =
        "https://dashboard.stripe.com" +
        "/customers/" +
        organization.customerId;

      stripeSubscriptionUrl =
        "https://dashboard.stripe.com" +
        "/subscriptions/" +
        organization.subscriptionId;
    } else {
      stripeCustomerUrl =
        "https://dashboard.stripe.com" +
        "/test" +
        "/customers/" +
        organization.customerId;

      stripeSubscriptionUrl =
        "https://dashboard.stripe.com" +
        "/test" +
        "/subscriptions/" +
        organization.subscriptionId;
    }
  }

  return (
    <OrganizationWrapper
      key={organization.id}
      active={user.organizationId === organization.id && type === "all"}
      type={type}
    >
      {type === "all" ? (
        <Flex align="center">
          <TableContent>
            <Flex widthSM="100%">
              <Typography tag="p" mb="0" width="50" bold>
                {organization.id}
              </Typography>
              <Typography tag="p" customSize="14" mb="0">
                {organization.name}
              </Typography>
            </Flex>
            <Flex widthSM="300px" justify="space-around" ml="20">
              <Typography tag="p" mb="0">
                {formatDate(organization.createdAt)}
              </Typography>
              <Typography tag="p" mb="0">
                {formatDate(organization.updatedAt)}
              </Typography>
            </Flex>
            <Flex widthSM="100px" justify="space-around" ml="20">
              <Typography tag="a" href={url} target="_blank">
                <Icon icon="preview" pointer mr="0" />
              </Typography>
            </Flex>
            <Flex widthSM="75px" justify="space-around" ml="20">
              {user.organizationId === organization.id ? null : (
                <Icon
                  icon="arrows_repeat"
                  pointer
                  scale="1.1"
                  onClick={() => handleChangeClick(organization.id)}
                />
              )}
            </Flex>
          </TableContent>
        </Flex>
      ) : type === "current" ? (
        <LargeOrganizationCard>
          <Grid fluid>
            <Row>
              <Col>
                <Typography tag="h1" mb="15">
                  {organization.name}
                </Typography>
              </Col>
            </Row>
            <Row>
              <Col size={10} break="sm" smallerInnerGutter>
                <InputFieldWithLabel
                  byline="Organization ID:"
                  value={organization.id}
                  noBorder
                  disabled
                />
                <InputFieldWithLabel
                  byline="Organization UUID:"
                  value={organization.uuid}
                  noBorder
                  disabled
                />
                <InputFieldWithLabel
                  byline="Created at"
                  value={formatDate(organization.createdAt)}
                  noBorder
                  disabled
                />
                <InputFieldWithLabel
                  byline="Updated at"
                  value={formatDate(organization.updatedAt)}
                  noBorder
                  disabled
                />
              </Col>
              <Col size={10} break="sm" smallerInnerGutter>
                <InputFieldWithLink
                  byline="Career page"
                  value={url}
                  href={url}
                  noBorder
                  notEditable
                  readOnly
                  mt="0"
                />
                <InputFieldWithLink
                  byline="Stripe customer"
                  value={organization.customerId}
                  href={stripeCustomerUrl}
                  noBorder
                  notEditable
                  readOnly
                  mt="0"
                />
                <InputFieldWithLink
                  byline="Stripe subscription"
                  value={organization.subscriptionId}
                  href={stripeSubscriptionUrl}
                  noBorder
                  notEditable
                  readOnly
                  mt="0"
                />
                <InputLabel byline="Support mode">
                  <LabelByline>Support mode</LabelByline>
                </InputLabel>
                <PrimaryButton
                  red
                  uppercase
                  icon
                  fullWidth
                  justifyContent="center"
                  onClick={() => handleChangeClick(user.superOrganizationId)}
                >
                  Exit support mode
                  <Icon icon="arrrow_left_from_bracket" mr="20" scale="2" />
                </PrimaryButton>
              </Col>
            </Row>
          </Grid>
        </LargeOrganizationCard>
      ) : null}
    </OrganizationWrapper>
  );
};
export default OrganizationCard;
