import React, { useState, useEffect } from "react";
import { StyledSharedShortlistTopBar } from "./SharedShortlistTopBar.styled.js";
import { Grid, Row, Col } from "../../../../components/Grid";
import { Icon } from "../../../../components";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "../../../../components/Typography";

import { useTranslation } from "react-i18next";
import CompanyLogo from "../../../../candidate/components/CompanyLogo";
import { selectPageWithHeader } from "../../../../candidate/views/Application/utils";

/**
 * This is a top bar used in application forms, candidate account page, and shortlist share page
 */
const SharedShortlistTopBar = ({
  basicShareInfo,
  orgBranding,
  orgPage,
  orgUrl,
  orgName,
  orgLogo,
  includeAccount,
  isAuthenticated,
  position,
  loadedPosition,
  onLanguageChange,
  user,
}) => {
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(
    user ? user.language : i18n.language
  );

  //change language to position language
  useEffect(() => {
    if (loadedPosition && position && position.positionLanguage) {
      const lang = position.positionLanguage;
      i18n.changeLanguage(lang);
      setActiveLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  //If we have a career page header do not use generic top bar
  const query = new URLSearchParams(useLocation().search);
  const cpid = query.get("cpid");
  const { header } = selectPageWithHeader(position?.pages, cpid);
  if (header) return <></>;

  return (
    <StyledSharedShortlistTopBar
      backgroundColor={orgBranding?.brandingColorOne || null}
    >
      <Grid expand>
        <Row>
          <Col hCenter>
            {orgName ? (
              <CompanyLogo
                path={orgUrl}
                name={orgName}
                logoPath={
                  position?.branding?.files[0]?.signedRequest || orgLogo
                }
              />
            ) : (
              <Link to="/">
                <Icon icon="logo_with_name" color="#fff" />
              </Link>
            )}
          </Col>
        </Row>
        <Row mt={50}>
          <Col vCenter flexDirection="column">
            <Typography tag="p" light mb={10} uppercase fontSize="12">
              Shortlist
            </Typography>
            <Typography tag="h1" bold light>
              {basicShareInfo?.position?.title}
            </Typography>
          </Col>
        </Row>
      </Grid>
    </StyledSharedShortlistTopBar>
  );
};

export default SharedShortlistTopBar;
