import React, { createContext, useContext, useReducer, useEffect } from "react";

import { getOrganizations } from '../../api/endpoints/organization';
import { StateUserContext } from '../../context/UserContext';

export const DispatchSuperContext = createContext();
export const StateSuperContext = createContext();

const superReducer = (state, action) => {
  switch (action.type) {
    case 'load_organizations': {
      return {
        ...state,
        isLoading: false,
        organizations: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const SuperProvider = ({ children }) => {
  const { isAuthenticated, reload, user } = useContext(StateUserContext);

  const superInitialState = {
    isLoading: false,
    auth0Loading: false,
    organizations: {},
    error: "",
    reload: false,
  };

  //load organization
  useEffect(() => {

    const getOrg = async () => {
      try {
        const organizations = await getOrganizations();
        if (organizations.success) {
          dispatch({ type: 'load_organizations', payload: organizations.data, });
        }
      } catch (err) {
        console.log("An error getting organizations occurred: ", err);
      }
    };

    // Only load organizations if authenticated
    if (isAuthenticated) {
      // Check if user has "super" in their permissions
      if (user?.permissions?.includes("super")) {
        getOrg();
      }
    }
  }, [isAuthenticated, reload]);

  const [state, dispatch] = useReducer(superReducer, superInitialState);

  return (
    <DispatchSuperContext.Provider value={dispatch}>
      <StateSuperContext.Provider value={state}>
        {children}
      </StateSuperContext.Provider>
    </DispatchSuperContext.Provider>
  );
};