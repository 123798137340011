import { useContext, useState, useEffect } from "react";
import { useFetchApplications } from "./useFetchApplications";
import { ApplicationCard } from "..";
import { ApplicationParamsContext } from "./ApplicationContextWrapper";
import { DispatchErrorContext } from "../../../context/ErrorContext";

/**
 * useSearch include search bar
 * useSelect include select and menu
 * useIndex change active application on click
 */
const ApplicationList = ({
  useIndex, //Change active application when clicking on application card
  id,
}) => {
  const dispatchError = useContext(DispatchErrorContext);
  const { state, dispatch } = useContext(ApplicationParamsContext); //Params
  const { error, data, status } = useFetchApplications({ ...state.params });

  //ALL APPLICATIONS FROM REACT QUERY FLATTENED TO A SINGLE ARRAY
  const [flattenedList, setFlattenedList] = useState([]);

  //SEND LIST TO CONTEXT
  useEffect(() => {
    if (status === "success" && data?.pages) {
      dispatch({
        type: "update_list",
        payload: data?.pages?.flatMap((page) => page?.data),
      });
      setFlattenedList(data?.pages?.flatMap((page) => page?.data));
    } else {
      setFlattenedList([]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatchError({ type: "set_error", payload: error.message });
    }
  }, [error]);

  // Filter the flattenedList based on the id prop
  // const filteredList = flattenedList.filter((app) => app.id === id);

  return (
    <>
      {status === "success" && data && (
        <>
          {flattenedList.map((app) => (
            <ApplicationCard
              key={app.id}
              useIndex={useIndex}
              application={app}
              onClick={() => {
                dispatch({
                  type: "set_index",
                  payload: app.id,
                });
              }}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ApplicationList;
