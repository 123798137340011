// React
import React, { useEffect, useState, useContext } from "react";

// Custom hooks - utility functions - API endpoints
import {
  formatDate,
  formatAmount,
  statusText,
} from "../../../../utils/various";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  cancelAddon,
  reactivateAddon,
} from "../../../../api/endpoints/subscription";
import usePurchaseAddon from "../../../hooks/usePurchaseAddon";

// Components
import { Icon, PurchaseModal, BoxLoader } from "../../../../components";
import { Box, CancelSub } from "./style";
import { Typography } from "../../../../components/Typography";
import { SettingsBlockContent } from "../../Settings/Settings.styled";
import { PrimaryButton } from "../../../../components/Button";
import { SmallerCardContainer } from "../../../../components/SmallCard/SmallCard.styled";
import { Flex } from "../../../style";
import PaymentModal from "../../../../components/PaymentModal/PaymentModal";

// State management
import { DispatchUserContext } from "../../../../context/UserContext";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { AddCareerButton } from "../../../components/cp/SiteNav/SiteNav.styled";
import { DispatchMessageContext } from "../../../../context/MessageContext";
import { useFetchSites } from "../../../components/cp/useFetchSites";

// Block representing an addon or product such as basic / position / recruiter / career page / internships
// Show status of subscription / Show if it set to cancel
// Show price pr. period
// Show product title and product description / name
// Can cancel / reactive / purchase

const productSetter = (type, data, t) => {
  let productData = {
    title: "",
    desc: "",
    type: type,

    status: "",
    prices: [],
    price: "",
    fee: "",
    currency: "",
    interval: "",
    startDate: "",
    endDate: "",
    cancel_at: "",
    canCancel: false,
    canReactivate: false,
    canPurchase: false,
    linkTo: "",

    productId: "",
    subscriptionId: "",
  };

  const PURCHASABLE_ADDONS = ["Career page", "Position", "Recruiter"];

  switch (type) {
    // Main subscription in left side
    case "main": {
      productData = {
        ...productData,
        title: data?.product?.name,
        description: data?.product?.description,
        status: data?.status,
        price: data?.plan?.amount,
        currency: data?.plan?.currency,
        interval: data?.plan?.interval,
      };
      break;
    }

    // Unpurchased addons such as Recruiter / Position / Career Page
    case "availableAddon":
    case "addCP": {
      productData = {
        ...productData,
        title: data?.name,
        description: data?.description,
        productId: data?.id,
        status: "Not active",
        price: data?.prices?.[0]?.unit_amount,
        prices: data?.prices,
        fee: data?.prices?.[1]?.unit_amount,
        currency: data?.prices?.[0]?.currency,
        interval: data?.prices?.[0]?.recurring?.interval,
        canPurchase: true,
      };
      break;
    }

    // Active (purchased) addons such as Recruiter / Position / Career Page
    case "normalAddon": {
      productData = {
        ...productData,
        title: data.product.name,
        description: data.product.description,
        status: data.subscription.status,
        price: data.subscription.plan.amount,
        currency: data.subscription.plan.currency,
        interval: data.subscription.plan.interval,
        cancel_at: data.subscription.cancel_at,
        canCancel: !data.subscription.cancel_at,
        canReactivate:
          !!data.subscription.cancel_at &&
          PURCHASABLE_ADDONS.indexOf(data.product.name) > -1,
        linkTo: null,
        subscriptionId: data.subscription.id,
        productId: data.product.id,
      };
      break;
    }
    default:
  }
  return productData;
};

const Product = ({
  type,
  subscription,
  isLoading,
  setIsLoading,
  isPurchased,
  isMissingPaymentMethod,
  onButtonClick,
  title,
  adds,
  index,
  cp,
  count,
  description,
}) => {
  const { t } = useTranslation();
  const [product, setProduct] = useState(productSetter(type, subscription, t));
  const [addonModal, setAddonModal] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(true);

  const {
    state: { privileges, hasPayment },
  } = useContext(SubscriptionContext);

  useEffect(() => {
    setProduct(productSetter(type, subscription, t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, subscription]);

  const userDispatch = useContext(DispatchUserContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const modalDispatch = useContext(DispatchMessageContext);

  const { handlePurchase, isLoading: purchaseIsLoading } = usePurchaseAddon(
    () => {
      userDispatch({ type: "reload" });
    }
  );

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(purchaseIsLoading);
    }
  }, [purchaseIsLoading]); //eslint-disable-line

  const handleCancellation = async () => {
    try {
      setIsLoading(true);
      const request = {
        params: { subscription_id: product.subscriptionId },
      };
      const response = await cancelAddon(request);
      if (response.success) {
        userDispatch({ type: "reload" });
        setIsLoading(false);
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      errorDispatch({ type: "set_error", payload: err.message });
      console.log(err);
    }
  };

  const handleReactivation = async () => {
    try {
      setIsLoading(true);
      const request = {
        params: { subscriptionId: product.subscriptionId },
      };
      const response = await reactivateAddon(request);
      if (response.success) {
        userDispatch({ type: "reload" });
        setIsLoading(false);
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      errorDispatch({ type: "set_error", payload: err.message });
      console.log(err);
    }
  };

  /**
   * State variable to track component mounted state
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true); // Component is mounted
    return () => {
      setIsMounted(false); // Component is unmounted
    };
  }, []);

  const sites = useFetchSites();
  const [siteSlug, setSiteSlug] = useState("");

  useEffect(() => {
    const cpSlug = sites?.sites?.find((site) => site.main)?.slug;
    if (siteSlug) {
      setSiteSlug(`cp/site/${cpSlug}`);
    } else {
      setSiteSlug("cp/site/da");
    }
  }, [sites]);

  return (
    <>
      {type === "main" && (
        <SettingsBlockContent flex spaceBetween>
          <div>
            <Typography tag="p" nomargin mb="5" bold>
              {t("common.currentSubscription", "Current subscription")}{" "}
            </Typography>
            {isLoading ? (
              <BoxLoader tiny />
            ) : (
              <Typography tag="p" lowOpacity nomargin fontSizeLG="12">
                {product ? (
                  <>
                    <span style={{ fontWeight: "bold" }}>
                      {product.title}:{" "}
                    </span>
                    {formatAmount(product.price, product.currency)}/
                    {t("common." + product.interval)}
                  </>
                ) : (
                  <>
                    {t(
                      "common.noSubscription",
                      "You do not have an active subscription."
                    )}
                  </>
                )}
              </Typography>
            )}
          </div>
          <Flex
            flexDirection="column"
            flexDirectionLG="row"
            align="flex-start"
            alignItemsLG="center"
            widthLG="auto"
          >
            {!product.title === "Starter" && (
              <Typography
                tag="p"
                nomargin
                mr="20"
                mt="10"
                mtLG="0"
                lowercase
                fontSizeLG="12"
              >
                {product?.title === "Basic" ? "3" : "10"}{" "}
                {t("common.activePositions")},{" "}
                {product?.title === "Basic" ? "2" : "4"}{" "}
                {t("common.recruiter.plural")},{" "}
                {product?.title === "Basic" ? "1" : "2"}{" "}
                {t("common.careerSite")}
              </Typography>
            )}
            <PrimaryButton onClick={() => onButtonClick()} mt="10" mtLG="0">
              {t("common.changeSubscription")}
              <Icon icon="change" mr="12" />
            </PrimaryButton>
          </Flex>
        </SettingsBlockContent>
      )}

      {type === "addCP" && (
        <>
          {!hasPaymentMethod && (
            <PaymentModal
              onCancel={() => setHasPaymentMethod(true)}
              returnUrl={siteSlug}
            />
          )}
          <AddCareerButton
            onClick={() => {
              if (!hasPayment) {
                setHasPaymentMethod(false);
              } else {
                setAddonModal(true);
                modalDispatch({ type: "show_modal", payload: true }); // Set showModal state to true
              }
            }}
          >
            <Icon icon="plus" scale="0.9" />
          </AddCareerButton>
        </>
      )}

      {type === "availableAddon" && (
        <>
          {!hasPaymentMethod && (
            <PaymentModal
              onCancel={() => setHasPaymentMethod(true)}
              returnUrl={"settings/subscription"}
            />
          )}
          <SmallerCardContainer>
            {product.linkTo && <Link to={product.linkTo} />}
            <div>
              <Flex justify="space-between">
                <Typography tag="p" nomargin mb="10" bold>
                  {title}
                </Typography>
                <Typography tag="p" nomargin mb="10" lowOpacity>
                  {count}
                </Typography>
              </Flex>

              {!product.canPurchase && (
                <Box
                  color={
                    product.status === "trialing"
                      ? "blue"
                      : product.status === "active"
                      ? "green"
                      : "red"
                  }
                >
                  {statusText(product.status, t)}
                </Box>
              )}

              {product.cancel_at && (
                <Box color="grey">
                  {t("component.subscription.product.cancels_at")}:
                  {formatDate(product.cancel_at * 1000)}
                </Box>
              )}
              {product.status === "trialing" && product.trial_end && (
                <Box color="grey">
                  {t("component.subscription.product.trial_ends")}:
                  {formatDate(product.trial_end * 1000)}
                </Box>
              )}
            </div>
            <div>
              {product.canCancel && (
                <Box>
                  <CancelSub
                    onClick={() => {
                      handleCancellation();
                    }}
                  >
                    <span>{t("common.cancel")}</span>
                  </CancelSub>
                </Box>
              )}

              {product.canReactivate && (
                <Box>
                  <CancelSub
                    onClick={() => {
                      handleReactivation();
                    }}
                    disabled={isMissingPaymentMethod}
                  >
                    <span>{t("common.activate", "activate")}</span>
                  </CancelSub>
                </Box>
              )}

              {product.canPurchase && (
                <>
                  {index === 0 ? (
                    // If first index meaning it is career page
                    <Typography
                      tag="p"
                      nomargin
                      pointer={privileges.privileges.career_page < 2}
                      large
                      lowOpacity
                      onClick={() => {
                        if (!hasPayment) {
                          setHasPaymentMethod(false);
                          return;
                        }
                        if (privileges.privileges.career_page < 2) {
                          setAddonModal(true);
                          modalDispatch({ type: "show_modal", payload: true }); // Set showModal to true
                        }
                      }}
                    >
                      {privileges.privileges.career_page >= 2
                        ? t("common.maximumCareer")
                        : `+ ${adds}`}
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        tag="p"
                        nomargin
                        pointer
                        large
                        lowOpacity
                        onClick={() => {
                          if (!hasPayment) {
                            setHasPaymentMethod(false);
                            return;
                          }
                          setAddonModal(true);
                          modalDispatch({ type: "show_modal", payload: true }); // Set showModal to true
                        }}
                      >
                        + {adds}
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
          </SmallerCardContainer>
        </>
      )}
      {addonModal ? (
        <PurchaseModal
          onCancel={() => {
            setAddonModal(false);
            modalDispatch({ type: "show_modal", payload: false }); // Set showModal to false
          }}
          onConfirm={(priceId) => {
            handlePurchase(priceId);
            // setIsLoading(true);
          }}
          prices={product.prices}
          title={adds}
          productTitle={product.title}
          desc={description}
          type="addonModal"
          isLoading={purchaseIsLoading}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Product;
