import { useContext } from "react";
// import { useContext, useEffect, useState } from 'react';
// import { CurriculumVitae, VerticalDivider } from "../../../components";
// import Summary from "../../components/Summary/Summary";
import { Col, Row } from "../../../components/Grid";
import {
  ApplicationContextWrapper,
  ApplicationList,
} from "../../components/ApplicationList";
import { ApplicationParamsContext } from "../../components/ApplicationList/ApplicationContextWrapper";
// import KanbanCard from "../Position/Kanban/KanbanCard/KanbanCard";
// import { StatePositionContext } from "../../context/PositionContext";
// import { useFetchApplications } from "../../components/ApplicationList/useFetchApplications";
import Application from "../Application/Application";

/**
 * A clickable list that show CV for the active application ( use ApplicationContextWrapper )
 * positionId - used for viewing in cockpit (internal)
 * positionUuid - used when viewing a shared list! (external)
 * useTracker - use a tracker ( only used for external viewing, and not internal preview )
 * @returns jsx
 */
const SharedShortlist = ({ positionId, positionUuid, useTracker, info }) => {
  return (
    <ApplicationContextWrapper
      uuid={positionUuid}
      positionId={positionId}
      shortlist={true}
      size={1000}
      useTracker={useTracker}
    >
      <Row mt={30} manageShareholderParent>
        <Col size={9} break="md" manageShareholderShortlist>
          <ApplicationList id={info?.position?.id} />
        </Col>
        <Col size={14} break="md" manageShareholderApplication>
          <ViewApplication id={info?.position?.id} />
        </Col>
      </Row>
    </ApplicationContextWrapper>
  );
};

const ViewApplication = () => {
  const {
    state: { list, index },
  } = useContext(ApplicationParamsContext);

  return (
    <>
      {list.length > 0 && (
        <>
          {/* <Summary application={list[index]} />
          <CurriculumVitae data={list[index]} /> */}
          <Application application={list} index={index} manageShareholder />
        </>
      )}
    </>
  );
};

export default SharedShortlist;
