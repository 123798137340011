// React
import React, { useState, useContext } from "react";

// Utility, translations, and hooks
import { useTranslation } from "react-i18next";
import { pageUrl } from "../../../../utils/redirectUrls";
import { useFetchSites } from "../../../components/cp/useFetchSites";
import { titleValidation } from "../../../../utils/validation";
import { modifyPage } from "../../../components/cp/SinglePage/QuillEditor/utils";
import { updatePage } from "../../../../api/endpoints/cp";
import { useParams } from "react-router";
import { useFetchPages } from "../../../components/cp/useFetchPages";
import { updatePosition } from "../../../../api/endpoints/position";
import { preflight } from "../validation";
import { validation } from "../validation";

// Components
import { Typography } from "../../../../components/Typography";
import SinglePage from "../../../components/cp/SinglePage/SinglePage";
import TitleBar from "../../../components/TitleBar/TitleBar";
import {
  StyledTopBar,
  TopBarBottom,
  TopBarBottomContent,
  TopBarTop,
} from "../../../components/TopBar/style";
import { Flex } from "../../../style";
import { Icon, TogglePosition } from "../../../../components";
import PositionPublish from "../../../components/cp/SinglePage/PageMetaEditor/MetaGroups/PublishMG/PositionPublish";
import { StyledGotoUrl } from "../../../components/cp/SinglePage/PageMetaEditor/MetaGroups/PublishMG/style";
import LanguageRadio from "../../../../components/LanguageSelect/LanguageRadio";
import ButtonLoading from "../../../../components/Loader/ButtonLoading";

// Styled components
import {
  Bar,
  EditPositionFixed,
  EditPositionWrapper,
} from "./EditPositionView.styled";

// State management
import {
  StatePositionContext,
  DispatchPositionContext,
} from "../../../context/PositionContext";
import { PrimaryButton } from "../../../../components/Button";
import PositionMetaEditor from "./PositionMetaEditor/PositionMetaEditor";
import { PageContext } from "../../../context/PageContext";
import { StateOrganizationContext } from "../../../context/OrganizationContext";
import { DispatchErrorContext } from "../../../../context/ErrorContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { StateMessageContext } from "../../../../context/MessageContext";

const EditPositionView = () => {
  const { t } = useTranslation();
  const { single } = useContext(StatePositionContext);
  const { state, dispatch } = useContext(PageContext);
  let { id } = useParams();
  const { activeSite } = useFetchSites(state.page.siteId);
  const { organization } = useContext(StateOrganizationContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const positionDispatch = useContext(DispatchPositionContext);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const [successfullyUpdated, setSuccesfullyUpdated] = useState(false);
  const [languageToSiteID, setLanguageToSiteID] = useState();

  const {
    state: { privileges },
  } = useContext(SubscriptionContext);

  const { error, data } = useFetchPages(id);

  function filterSites(sites, positionLanguage) {
    let filteredSites = sites?.filter((site) => site.frontpage);
    let langPrefix = positionLanguage.substring(0, 2);
    let siteWithEnPrefix = filteredSites?.find(
      (site) => site?.site?.title.substring(0, 2) === langPrefix
    );

    setLanguageToSiteID(siteWithEnPrefix?.site?.id);
  }

  const modalState = useContext(StateMessageContext);

  let thePageId = null;

  // Check if position ID matches the ID to verify that it is a position job ad page, rather than a regular CP
  if (data) {
    function findAndSetGlobalVariable(dataObject) {
      for (const obj of Object.values(dataObject)) {
        if (obj.positionId == id) {
          thePageId = obj.id;
          break;
        }
      }
    }
    findAndSetGlobalVariable(data.data);
  }

  //Saving page
  const onSaveHandler = async (publish) => {
    setUpdateLoading(true);
    // dispatch({ type: 'init_page' });
    try {
      if (state.page.title.length < 2)
        throw new Error("Page title must be minimum 2 characters long");
      if (!titleValidation(state.page.title))
        throw new Error('Page title can not contain "`$%^*_+=[]{};\'"|<>~"');
      state.page.blocks.forEach((block) => {
        if (block.files && Array.isArray(block.files)) {
          block.files = block.files.map((file) => file.uuid);
        }
      });
      let request;
      const modifiedRequestObject = modifyPage(state.page);
      request = {
        selector: state.page.id,
        body: modifiedRequestObject,
      };

      const response = await updatePage(request);

      if (!response.success) throw new Error(response.data.data);
      dispatch({ type: "page_loaded", payload: response.data });
    } catch (err) {
      errorDispatch({ type: "set_error", payload: err.message });
      dispatch({ type: "error" });
      console.log(err);
    }
  };
  /**
   * save the position in state on server!
   */
  const handleSave = async () => {
    try {
      const modified_single = preflight(single);

      // validate object to save
      await validation(modified_single);

      //can save if unsaved things // new or existing
      const request = {
        body: modified_single,
        selector: modified_single.id,
      };
      const response = modified_single.id;
      await updatePosition(request);
      if (!response.success)
        positionDispatch({
          type: "updated_position",
          payload: { data: response.data, t: t },
        });
      setTimeout(function () {
        setUpdateLoading(false);
      }, 500);
      setSuccesfullyUpdated(true);
    } catch (err) {
      setConfirmationError(true);
      setUpdateLoading(false);
      console.log(err);
    }
  };

  const clickHandler = () => {
    onSaveHandler();
    handleSave();
  };

  return (
    <>
      <ButtonLoading
        error={confirmationError}
        loading={updateLoading}
        success={successfullyUpdated}
      />
      <EditPositionFixed>
        <StyledTopBar position>
          <TopBarTop>
            <TitleBar type="position" />
          </TopBarTop>
          <TopBarBottom>
            <TopBarBottomContent page>
              <Flex
                widthSM="fit-content;"
                widthLG="100%"
                justifyLG="space-between"
                height="100%"
              >
                <LanguageRadio
                  name="positionLanguage"
                  onChange={(e) => {
                    filterSites(data?.data, single?.positionLanguage);
                    positionDispatch({
                      type: "update_position_field",
                      payload: ["positionLanguage", e.target.value],
                    });
                  }}
                  value={single.positionLanguage}
                />
                <Flex widthSM="fit-content">
                  <TogglePosition />
                  <PositionPublish />
                </Flex>
              </Flex>
            </TopBarBottomContent>
          </TopBarBottom>
        </StyledTopBar>
        <EditPositionWrapper>
          <Bar hide={modalState.showModal}>
            <div>
              <Flex
                pointer
                onClick={() =>
                  positionDispatch({
                    type: "edit_position_view",
                    payload: false,
                  })
                }
              >
                <Icon icon="arrow_left_small" />
                <Typography tag="p" large lowOpacity nomargin noWrap>
                  {t("page.position.back")}
                </Typography>
              </Flex>
            </div>
            <Flex justify="flex-end">
              {state?.page?.status === "publish" && (
                <StyledGotoUrl
                  position="relative"
                  target="_blank"
                  rel="noreferrer"
                  publish={state?.page?.status === "publish"}
                  href={
                    state?.page?.status === "publish"
                      ? pageUrl(
                          { ...state.page, site: activeSite },
                          organization
                        )
                      : null
                  }
                  onClick={
                    state?.page?.status === "publish"
                      ? null
                      : () => {
                          return false;
                        }
                  }
                >
                  <PrimaryButton transparent hideOnMobile>
                    {t("component.metaeditor.view_page", "View page")}
                    <Icon icon="preview" mr="10" $filter />
                  </PrimaryButton>
                </StyledGotoUrl>
              )}
              <Link to={{ pathname: single.url }} target="_blank">
                <PrimaryButton white hideOnMobile>
                  {t("common.viewPosition")}
                  <Icon icon="eye" mr="10" />
                </PrimaryButton>
              </Link>
              <PrimaryButton onClick={clickHandler} red ml="15">
                {t("common.update", "Update")}
                <Icon icon="update" mr="10" />
              </PrimaryButton>
            </Flex>
          </Bar>
          <PositionMetaEditor />
          <SinglePage pageId={thePageId} context="page" />
        </EditPositionWrapper>
      </EditPositionFixed>
    </>
  );
};

export default EditPositionView;
