/**
 * Everything regarding subscription, addons and privileges
 */

import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
} from 'react';
import {
  getAvailableAddons,
  getPrivileges,
  getSubscription,
} from '../../api/endpoints/subscription';
import { StateUserContext } from '../../context/UserContext';
import SubscriptionNotifications from '../components/SubscriptionNotifications';
import { HasNoSubscription } from "../../components/";

// @ts-ignore
export const SubscriptionContext = createContext();

export const initialSubscription = {
  isLoading: false,
  customer: {}, //the customer object (stripe)
  main: {
    subscription: {},
    subscription_action: {},
    subscription_action_payload: {},
  }, //the main subscription object
  hasPayment: null, // the organization has a valid payment method on hand ( maybe flagged for invoice )
  privileges: {
    privileges: {
      unsolicited: false,
      position: 0,
      recruiter: 0,
      career_page: 0,
    },
    activeItems: {},
    subscriptions: [],
  }, //what privileges are available for the organization
  available_addons: [],
  addons: {}, //the active addons bought by the customer ( maybe remove )
};

const subscriptionReducer = (state, action) => {
  switch (action.type) {
    case 'init_subscription': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'no_subscription': {
      return {
        privileges: {
          ...state.privileges,
          subscriptions: { hassubscription: false },
        },
        isLoading: false,
      };
    }
    case 'main_load': {
      let subscription_action = 'none';
      let subscription_action_payload;
      const main_subscription_response = action.payload;

      if (main_subscription_response?.action) {
        subscription_action = main_subscription_response.action;
        if (main_subscription_response.payload) {
          subscription_action_payload = main_subscription_response.payload;
        }
      }
      let customer = {};
      let subscription = {};
      let hasPayment = false;
      if (main_subscription_response.data && main_subscription_response.data.customer) {
        customer = main_subscription_response.data.customer;
      }
      if (main_subscription_response.data && main_subscription_response.data.subscription) {
        subscription = main_subscription_response.data.subscription;
      }
      if (main_subscription_response.data && main_subscription_response.data.hasPayment) {
        hasPayment = main_subscription_response.data.hasPayment;
      }

      return {
        ...state,
        customer: customer,
        main: {
          subscription: subscription,
          subscription_action: subscription_action,
          subscription_action_payload: subscription_action_payload,
        },
        hasPayment: hasPayment,
      };
    }
    case 'privileges_load': {
      return {
        ...state,
        privileges: {
          ...action.payload,
        },
      };
    }
    case 'available_addons_load': {
      return {
        ...state,
        available_addons: action.payload,
      };
    }
    case 'loading_finished': {
      return {
        ...state,
        isLoading: false,
      };
    }

    //Update privileges ( after purchased addon, changed main tier )
    case 'update_privilege': {
      const { key, value } = action.payload; // position , 1 increment position privilege by 1

      return {
        ...state,
        privileges: {
          ...state.privileges,
          privileges: {
            ...state.privileges.privileges,
            [key]: state.privileges.privileges[key] + value,
          },
        },
      };
    }

    //Update active items, ie when new recruiter added or position activated / deactivated
    case 'update_active_items': {
      const { key, value } = action.payload;
      return {
        ...state,
        privileges: {
          ...state.privileges,
          activeItems: {
            ...state.privileges.activeItems,
            [key]: state.privileges.activeItems[key] + value,
          },
        },
      };
    }
    case 'update_subscription': {
      return {
        ...state,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const SubscriptionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    subscriptionReducer,
    initialSubscription
  );

  const { isAuthenticated, reload } = useContext(StateUserContext);
  useEffect(() => {
    (async () => {
      try {
        if (!isAuthenticated) return;

        // @ts-ignore
        dispatch({ type: 'init_subscription' });

        //Get main subscription and state of main subscription
        const main_response = await getSubscription(); // Returns data : { customer, subscription, hasPayment },  action, payload

        if (main_response.data.hasSubscription === false) {
          dispatch({ type: 'no_subscription' });
          return;
        }

        if (main_response.success) {
          // @ts-ignore
          dispatch({
            type: 'main_load',
            payload: main_response,
          });

          //Get privileges
          const privileges_response = await getPrivileges(); // Returns privileges { privileges : {position: 10, recruiter : 4, career_page : 1}}
          // @ts-ignore
          dispatch({
            type: 'privileges_load',
            payload: privileges_response.data,
          });

          //Get availabe addons
          const available_addons_response = await getAvailableAddons();
          //@ts-ignore
          dispatch({
            type: 'available_addons_load',
            payload: available_addons_response.data,
          });

        }
        //@ts-ignore
        dispatch({ type: 'loading_finished' });
      } catch (err) { }
    })();
  }, [isAuthenticated, reload]);


  if (state.privileges.subscriptions.hassubscription === false) {
    return (
      <HasNoSubscription />
    );
  }

  return (
    <SubscriptionContext.Provider value={{ state, dispatch }}>
      {children}
      <SubscriptionNotifications />
    </SubscriptionContext.Provider>
  );
};
